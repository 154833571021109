import React from "react";
import {
  FaListAlt,
  FaQrcode,
  FaStar,
  FaMapMarkerAlt,
  FaTelegramPlane,
} from "react-icons/fa";
import "./PaymentInfo.css"; // Import the styles
import restaurantBg from "../assets/image/restaurant.webp"; // Replace with the actual image path
import { Link } from "react-router-dom";

const PaymentInfo = () => {
  return (
    <div
      className="restaurant-page-container"
      style={{ backgroundImage: `url(${restaurantBg})` }}
    >
      <div className="restaurant-page-overlay">
        <div className="restaurant-content-wrapper">
          {/* Left Section */}
          <div className="restaurant-left">
            <h1>EXPLORE FINEST DINING</h1>
            <h2>QR Code Menus for Restaurants, Cafes, and Hotels</h2>
            <p>
              Discover a seamless dining experience with our QR Menu solution.
              Effortlessly browse menus, order meals, and enjoy contactless
              service with just a scan.
            </p>
            <p>Available across restaurants, cafes, and hotels nationwide</p>
          </div>

          {/* Right Section */}
          <div className="restaurant-right">
            <div className="right-card">
              <h1>All-In-One Digital Menu Solution</h1>
              <ul>
                <li>
                  <FaQrcode className="icon" /> Branded QR codes for your
                  business.
                </li>{" "}
                <li>
                  <FaStar className="icon" /> Promote your top dishes.
                </li>
                <li>
                  <FaListAlt className="icon" />{" "}
                  <small>
                    {" "}
                    All menu options at your customers' fingertips.
                  </small>
                </li>
                <li>
                  <FaMapMarkerAlt className="icon" />{" "}
                  <small> Make it easy for customers to find you.</small>
                </li>
              </ul>
              <div className="powered-by">Powered by QRMenu</div>
              <div className="price-section">
                <h4>Starting at 9,000 Birr / year</h4>
                <button className="order-now">
                  <Link to="/restaurant_registration">Register Now</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fixed "Chat Us on Telegram" Button */}
      <a
        href="https://t.me/huzeyfa6"
        target="_blank"
        rel="noopener noreferrer"
        className="telegram-chat-button"
      >
        <FaTelegramPlane className="telegram-icon" /> Chat Us on Telegram
      </a>
    </div>
  );
};

export default PaymentInfo;
