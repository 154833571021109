import React from "react";
import Navbar from "../components/common/navbar/Navbar";
import Footer from "../components/common/Footer/Footer";
import PaymentInfo from "../components/payment-info/PaymentInfo";

const PaymentPage = () => {
  return (
    <>
      <Navbar />
      <PaymentInfo />
      <Footer />
    </>
  );
};

export default PaymentPage;
