import React, { useState } from "react";
import { Upload, Button, Form, Input, Select } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";
import {
  regions,
  subCitiesByRegion,
  locationLists,
  businessTypes,
} from "../../../services/data";
import { LocationRequest } from "../../common/location-selector/LocationSelector";

const { Option } = Select;

const RestaurantProfileEditForm = ({
  form,
  handleModalOk,
  onChange,
  onPreview,
  fileList,
}) => {
  const [selectedRegion, setSelectedRegion] = useState(null);

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const locationOptions = locationLists.map((location) => (
    <Option key={location} value={location}>
      {location}
    </Option>
  ));

  return (
    <Form onFinish={handleModalOk} form={form} layout="vertical">
      {/* Image Upload Field */}
      <Form.Item
        label="Restaurant Image"
        name="image"
        rules={[
          {
            validator: (_, value) => {
              if (fileList.length === 0) {
                return Promise.reject(new Error("Please upload an image!"));
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <ImgCrop rotationSlider>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            beforeUpload={() => false} // Prevent auto-upload
          >
            {fileList.length < 1 && (
              <Button icon={<UploadOutlined />}> Upload</Button>
            )}
          </Upload>
        </ImgCrop>
      </Form.Item>

      {/* Other Form Fields */}
      <Form.Item
        label="Restaurant Name"
        name="name"
        rules={[
          { required: true, message: "Please input the Restaurant Name!" },
        ]}
        className="form-item"
      >
        <Input placeholder="Enter name" />
      </Form.Item>

      <Form.Item
        label="Type of Business"
        name="typeOfBusiness"
        rules={[{ required: true, message: "Please select a type!" }]}
        className="form-item"
      >
        <Select
          showSearch
          placeholder="Select a type"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {businessTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {/* Address, Region, and SubCity Fields */}
      <Form.Item
        label="Select or Type Your Address"
        name="address"
        rules={[
          { required: true, message: "Please select or type your address!" },
        ]}
        className="form-item"
      >
        <Select
          showSearch
          placeholder="Select an address"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          allowClear
          dropdownRender={(menu) => (
            <>
              {menu}
              <Input
                style={{ margin: "8px", width: "93%" }}
                placeholder="Type your location"
              />
            </>
          )}
        >
          {locationOptions}
        </Select>
      </Form.Item>

      <Form.Item
        label="Region"
        name="region"
        rules={[{ required: true, message: "Please select a region!" }]}
        className="form-item"
      >
        <Select
          showSearch
          onChange={handleRegionChange}
          placeholder="Select a region"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {regions.map((region) => (
            <Option key={region} value={region}>
              {region}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {selectedRegion && (
        <Form.Item
          label="Sub City"
          name="subCity"
          rules={[{ required: true, message: "Please select a subcity!" }]}
          className="form-item"
        >
          <Select
            showSearch
            placeholder="Select a subcity"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {subCitiesByRegion[selectedRegion]?.map((subCity) => (
              <Option key={subCity} value={subCity}>
                {subCity}
              </Option>
            )) || []}
          </Select>
        </Form.Item>
      )}

      {/* Location Selector Component */}
      <Form.Item label="Location" name="location">
        <LocationRequest form={form} />
      </Form.Item>
    </Form>
  );
};

export default RestaurantProfileEditForm;
