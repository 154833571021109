import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import MenuDetail from "./components/menu/MenuDetail";
import NotFound from "./pages/NotFound";
// import Login from "./components/login-form/LoginFrom";
import RestaurantRegistration from "./components/common/registration-form/RestaurantRegistration";
import Profile from "./pages/Profile";
import "./styles/global.css";
import RestaurantDetail from "./components/restaurant/RestaurantDetail";
import { ApiComp } from "./services/api";
import Payment from "./pages/Payment";
import UserRegistration from "./components/common/registration-form/UserRegistration";
import Login from "./components/common/login-form/LoginFrom";

const App = () => {
  return (
    <Router>
      <ApiComp />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/app" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/restaurant/:id" element={<RestaurantDetail />} />
        <Route
          path="/restaurant/:restaurantId/menu/:id"
          element={<MenuDetail />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/payment" element={<Payment />} />
        <Route
          path="/restaurant_registration"
          element={<RestaurantRegistration />}
        />
        <Route path="/user_registration" element={<UserRegistration />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
