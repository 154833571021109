import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DefaultMenuImage from "../assets/image/menu.webp";
import Navbar from "../common/navbar/Navbar";
import Footer from "../common/Footer/Footer";
import { FiSearch } from "react-icons/fi";
import {
  FaUtensils,
  FaIceCream,
  FaPizzaSlice,
  FaDrumstickBite,
  FaLeaf,
  FaGlassMartini,
  FaChild,
  FaStar,
  FaAppleAlt,
} from "react-icons/fa";
import useFetch from "../../hooks/useFetch";
import Slider from "../slider/Slider";

const RestaurantDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch restaurant details
  const { get: fetchRestaurantDetails } = useFetch(
    `/publicApi/restaurant/${id}`,
    {
      setLoading,
      setError,
      setData: setRestaurant,
    }
  );

  useEffect(() => {
    fetchRestaurantDetails();
  }, [id]);

  const filteredMenu = restaurant?.Menus?.filter((item) => {
    const isInCategory =
      selectedCategory === "All" ||
      item.category
        .split(",")
        .map((cat) => cat.trim().toLowerCase())
        .includes(selectedCategory.trim().toLowerCase());
    const matchesSearchQuery = item.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    return isInCategory && matchesSearchQuery;
  })?.sort((a, b) => {
    const titleA = a.title.toLowerCase();
    const titleB = b.title.toLowerCase();
    const query = searchQuery.toLowerCase();

    // Items that start with the query come first
    const startsWithA = titleA.startsWith(query) ? -1 : 0;
    const startsWithB = titleB.startsWith(query) ? -1 : 0;
    if (startsWithA !== startsWithB) {
      return startsWithA - startsWithB;
    }

    // Items that contain the query earlier come next
    const indexA = titleA.indexOf(query);
    const indexB = titleB.indexOf(query);
    return indexA - indexB;
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <>
      <Navbar />
      <div className="p-6 mt-10 dark:bg-gray-900 dark:text-white min-h-screen">
        {/* Restaurant Image and Name */}
        <header className="text-center mb-8">
          {restaurant?.image && (
            <img
              src={`/images/restaurant/img/${restaurant.image}`}
              alt={restaurant?.name}
              className="mx-auto rounded-full w-32 h-32 object-cover mb-4 shadow-lg"
            />
          )}
          <h1 className="text-3xl font-bold mb-2">{restaurant?.name}</h1>
          <p className="text-lg text-gray-600">{restaurant?.address}</p>
        </header>

        {/* Search Input */}
        <div className="flex justify-center mb-8">
          <div className="relative w-full max-w-lg">
            <FiSearch
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-orange-500 dark:text-orange-300"
              size={20}
            />
            <input
              type="text"
              placeholder="Search menu items..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="shadow-lg p-3 w-full rounded-lg pl-14 border-2 border-orange-500 dark:border-orange-300 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:border-orange-500 transition-all duration-500"
            />
          </div>
        </div>

        {/* Category Icons */}
        <div className="relative mb-8">
          <div className="overflow-x-auto no-scrollbar">
            <div className="flex lg:flex-wrap lg:justify-center gap-4">
              {[
                { name: "All", icon: <FaUtensils /> },
                { name: "Appetizers/Starters", icon: <FaLeaf /> },
                { name: "Main Course", icon: <FaPizzaSlice /> },
                { name: "Lunch Special", icon: <FaDrumstickBite /> },
                { name: "Side Dishes", icon: <FaIceCream /> },
                { name: "Dessert", icon: <FaIceCream /> },
                { name: "Beverage", icon: <FaGlassMartini /> },
                { name: "Kids' Menu", icon: <FaChild /> },
                { name: "Special", icon: <FaStar /> },
                { name: "Juice", icon: <FaAppleAlt /> },
              ].map((category) => (
                <div
                  key={category.name}
                  onClick={() => setSelectedCategory(category.name)}
                  className={`cursor-pointer flex flex-col items-center text-yellow-500 ${
                    selectedCategory === category.name ? "font-bold" : ""
                  }`}
                >
                  <div className="text-2xl">{category.icon}</div>
                  <span className="text-sm">{category.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Menu Items */}
        <div className="flex flex-col items-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-7xl">
            {filteredMenu?.map((item) => (
              <div
                key={item.id}
                className="rounded-lg shadow-lg dark:shadow-lg overflow-hidden"
              >
                {typeof item.images === "string"
                  ? JSON.parse(item.images)
                  : [] && (
                      <>
                        {item.images.length > 1 ? (
                          <Slider images={item.images} />
                        ) : (
                          <img
                            alt={item.title}
                            src={`/images/menu/img/${item.images[0]}`}
                            className="w-full h-48 object-cover"
                          />
                        )}
                      </>
                    )}
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{item.title}</h2>
                  <p className="text-gray-600 dark:text-gray-300 mb-2">
                    {truncateText(item.description, 50)}
                  </p>

                  {/* Availability Status */}
                  <div className="text-gray-700 dark:text-gray-200 font-semibold mb-2">
                    {item.isAvailable ? (
                      <span className="text-green-500">Available</span>
                    ) : (
                      <span className="text-red-500">Not Available</span>
                    )}
                  </div>
                  <p className="text-gray-600 dark:text-gray-300 mb-2">
                    {item.category}
                  </p>
                  <p className="text-lg text-blue-700 font-bold mb-4">
                    Price: {item.price}
                  </p>
                  <button
                    onClick={() =>
                      navigate(
                        `/restaurant/${restaurant.resId}/menu/${item.id}`
                      )
                    }
                    className="w-full bg-orange-400 hover:bg-orange-500 dark:bg-orange-500 py-2 dark:hover:bg-orange-600 rounded-lg"
                  >
                    View Menu Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RestaurantDetail;
