import React, { useEffect, useState } from "react";
import { Modal, Form, message, Button, Layout, Card } from "antd";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";
import usePatch from "../../../hooks/usePatch";
import useDelete from "../../../hooks/useDelete";
import PostForm from "./PostForm";
import "./PostMenu.css"; // Import CSS file for custom styles
import DefaultMenuImage from "../../assets/image/menu.webp"; // Import default image
import {
  FaUtensils,
  FaCoffee,
  FaIceCream,
  FaPizzaSlice,
  FaDrumstickBite,
  FaLeaf,
  FaGlassMartini,
  FaChild,
  FaAppleAlt,
  FaStar,
} from "react-icons/fa";
import Slider from "../../slider/Slider";

// import { restaurants } from "../../../services/data";

const categories = [
  { name: "All", icon: <FaUtensils /> },
  { name: "Appetizers/Starters", icon: <FaLeaf /> },
  { name: "Main Course", icon: <FaPizzaSlice /> },
  { name: "Lunch Special", icon: <FaDrumstickBite /> },
  { name: "Side Dishes", icon: <FaIceCream /> },
  { name: "Dessert", icon: <FaIceCream /> },
  { name: "Beverage", icon: <FaGlassMartini /> },
  { name: "Kids' Menu", icon: <FaChild /> },
  { name: "Special", icon: <FaStar /> },
  { name: "Juice", icon: <FaAppleAlt /> },
];

const PostMenu = () => {
  //
  const [form] = Form.useForm();
  const [menuData, setMenuData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [editingMenuItem, setEditingMenuItem] = useState(null);

  const [menuLoading, setMenuLoading] = useState(false);
  const [menuError, setMenuError] = useState(null);
  const [menuRes, setMenuRes] = useState(null);

  const [menuCreateLoading, setMenuCreateLoading] = useState(false);
  const [menuCreateRes, setMenuCreateRes] = useState(null);
  const [menuCreateError, setMenuCreateError] = useState(null);

  const [menuUpdateLoading, setMenuUpdateLoading] = useState(false);
  const [menuUpdateRes, setMenuUpdateRes] = useState(null);
  const [menuUpdateError, setMenuUpdateError] = useState(null);

  const [menuDeleteLoading, setMenuDeleteLoading] = useState(false);
  const [menuDeleteRes, setMenuDeleteRes] = useState(null);
  const [menuDeleteError, setMenuDeleteError] = useState(null);
  ///
  // Fetch restaurant profile data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [restaurantData, setRestaurantData] = useState({});

  const { get: fetchRestaurant } = useFetch("/api/restaurant/current", {
    setLoading,
    setError,
    setData: setResponse,
  });
  useEffect(() => {
    fetchRestaurant();
  }, []);

  useEffect(() => {
    if (response && Array.isArray(response) && response.length > 0) {
      console.log("--------------restaurants", response[0]);
      // Access the first restaurant object
      setRestaurantData(response[0]);
    } else {
      console.error("No valid restaurant data found in the response");
      setRestaurantData({}); // Reset to an empty object if no valid data
    }
  }, [response]);
  ///
  const { post: createMenuItem, reset: resetMenuCreate } = usePost(
    `/api/menu`,
    {
      setLoading: setMenuCreateLoading,
      setError: setMenuCreateError,
      setData: setMenuCreateRes,
    }
  );

  const { patch: updateMenuItem, reset: resetMenuUpdate } = usePatch(
    `/api/menu/${editingMenuItem?.id}`,
    {
      setLoading: setMenuUpdateLoading,
      setError: setMenuUpdateError,
      setData: setMenuUpdateRes,
    }
  );

  const { remove: deleteMenuItem, reset: resetMenuDelete } = useDelete(
    `/api/menu`,
    {
      setLoading: setMenuDeleteLoading,
      setError: setMenuDeleteError,
      setData: setMenuDeleteRes,
    }
  );

  const { get: getMenu } = useFetch("api/menu", {
    setLoading: setMenuLoading,
    setError: setMenuError,
    setData: setMenuRes,
  });

  useEffect(() => {
    getMenu();
  }, []);

  useEffect(() => {
    console.log("menuRes------------", menuRes?.menus);

    if (menuRes) {
      setMenuData(menuRes?.menus);
    }
  }, [menuRes]);

  useEffect(() => {
    if (menuCreateRes?.message) {
      message[menuCreateRes?.status](menuCreateRes?.message);
      if (menuCreateRes?.status === "success") {
        setIsModalVisible(false);
        form.resetFields();
        getMenu();
      }
      resetMenuCreate();
    }
  }, [menuCreateRes]);

  useEffect(() => {
    if (menuCreateError?.message) {
      message[menuCreateError?.status || "error"](menuCreateError?.message);
      resetMenuCreate();
    }
  }, [menuCreateError]);

  useEffect(() => {
    if (menuDeleteRes?.message) {
      message.success(menuDeleteRes.message);
      if (menuDeleteRes?.status === "success") {
        getMenu();
      }
      resetMenuDelete();
    }
    if (menuDeleteError?.message) {
      message.error(menuDeleteError.message);
    }
  }, [menuDeleteRes, menuDeleteError]);

  useEffect(() => {
    if (menuUpdateRes?.message) {
      message.success(menuUpdateRes.message);
      if (menuUpdateRes?.status === "success") {
        setIsModalVisible(false);
        getMenu();
      }
      resetMenuUpdate();
    }
    if (menuUpdateError?.message) {
      message.error(menuUpdateError.message);
    }
  }, [menuUpdateRes, menuUpdateError]);

  const handleAdd = () => {
    setIsPost(true);
    setIsUpdate(false);
    setFileList([]); // Reset fileList when adding a new item
    setIsModalVisible(true);
  };

  const handleDelete = (itemId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this menu item?",
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        deleteMenuItem(itemId);
      },
    });
  };

  const handleModalCancel = () => setIsModalVisible(false);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleUpdate = (item) => {
    setIsUpdate(true);
    setIsPost(false);
    setEditingMenuItem(item);

    // Check if item.images is already an array or a JSON string
    let imagesArray = [];
    try {
      imagesArray = Array.isArray(item.images)
        ? item.images
        : JSON.parse(item.images); // Ensure it's an array
    } catch (error) {
      console.error("Error parsing images:", error);
      imagesArray = []; // Fallback to an empty array if parsing fails
    }

    const initialFileList = imagesArray.map((img, index) => ({
      uid: index.toString(),
      name: img,
      status: "done",
      url: `/images/menu/img/${img}`,
    }));

    setFileList(initialFileList); // Set initial file list correctly

    form.setFieldsValue({
      title: item.title,
      description: item.description,
      price: item.price,
      category: item.category,
      isAvailable: item.isAvailable,
    });

    setIsModalVisible(true);
  };

  // Frontend code: When handling image removal
  // Handle image removal and addition
  // Handle image removal and addition
  const onChange = ({ fileList: newFileList, file }) => {
    setFileList(newFileList);

    // Initialize imagesToRemove array from form data or create a new one
    let imagesToRemove = form.getFieldValue("existingImagesToRemove") || [];

    if (file.status === "removed") {
      // Check if the removed file is an existing image (not a new upload)
      if (!file.originFileObj) {
        // Add removed image to imagesToRemove array
        imagesToRemove = [...imagesToRemove, file.name];
      }

      // Update form field to reflect images marked for removal
      form.setFieldsValue({ existingImagesToRemove: imagesToRemove });
    }

    console.log("File list after change:", newFileList);
    console.log("Images to remove after change:", imagesToRemove);
  };

  // Ensure images are updated correctly in handleModalOk
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      values.restaurantId = restaurantData.id;

      if (!values.restaurantId) {
        return message.error("Restaurant Not Registered");
      }

      const formData = new FormData();
      Object.keys(values).forEach((key) => formData.append(key, values[key]));

      // Collect image files
      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("images", file.originFileObj); // Appending image file objects
        } else {
          formData.append("images", file.name); // Existing images' names
        }
      });

      // Handle removed images
      const imagesToRemove = form.getFieldValue("existingImagesToRemove") || [];
      formData.append("existingImagesToRemove", JSON.stringify(imagesToRemove));

      if (isPost) {
        await createMenuItem(formData); // Sending images
      } else if (isUpdate) {
        await updateMenuItem(formData);
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };

  // Filter menu items by category
  const filteredMenu = menuData?.filter((item) => {
    return selectedCategory === "All" || item?.category === selectedCategory;
  });
  const [seeMore, setSeeMore] = useState(false);
  const MAX_LENGTH = 200;

  const toggleSeeMore = () => {
    setSeeMore(!seeMore);
  };

  return (
    <div className="p-2 my-2 dark:bg-gray-900 dark:text-white">
      <div className="max-w-7xl p-6 my-8 mx-auto bg-white dark:bg-gray-800 rounded-alg border-2 border-black-400 dark:border-black-300 overflow-hidden">
        {/* Add Menu Item Button */}
        <div className="flex justify-center mb-4">
          <Button type="primary" onClick={handleAdd} className="m-12">
            Add Menu Item
          </Button>
        </div>

        {/* Category Filter */}
        <div className="flex justify-center overflow-x-auto whitespace-nowrap my-4">
          {categories.map((category) => (
            <div
              key={category.name}
              onClick={() => setSelectedCategory(category.name)}
              className={`inline-block cursor-pointer text-center mx-4 ${
                selectedCategory === category.name
                  ? "text-yellow-500"
                  : "text-gray-500"
              }`}
            >
              <div className="text-2xl">{category.icon}</div>
              <span className="text-xs">{category.name}</span>
            </div>
          ))}
        </div>

        {/* Menu List */}
        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-max">
            {filteredMenu?.map((item) => (
              <div
                style={{ boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)" }}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden"
              >
                {/* Conditionally render slider if there are multiple images */}
                {typeof item.images === "string"
                  ? JSON.parse(item.images)
                  : [] && (
                      <>
                        {item.images.length > 1 ? (
                          <Slider images={item.images} />
                        ) : (
                          <img
                            alt={item.title}
                            src={`/images/menu/img/${item.images[0]}`}
                            className="w-full h-48 object-cover"
                          />
                        )}
                      </>
                    )}

                <div className="p-2">
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                  <p className="text-gray-700 dark:text-gray-200">
                    {seeMore || item.description.length <= MAX_LENGTH
                      ? item.description
                      : `${item.description.substring(0, MAX_LENGTH)}... `}
                    {item.description.length > MAX_LENGTH && (
                      <span
                        onClick={toggleSeeMore}
                        className="text-blue-500 cursor-pointer"
                      >
                        {seeMore ? " See Less" : " See More"}
                      </span>
                    )}
                  </p>

                  {/* Availability Status */}
                  <div className="text-gray-700 dark:text-gray-200 font-semibold">
                    {item.isAvailable ? (
                      <span className="text-green-500">Available</span>
                    ) : (
                      <span className="text-red-500">Not Available</span>
                    )}
                  </div>

                  {/* Price */}
                  <div className="text-gray-700 dark:text-gray-200 font-semibold">
                    {item.price}
                  </div>

                  {/* Action Buttons */}
                  <div className="flex justify-between mt-2">
                    <Button
                      onClick={() => handleUpdate(item)}
                      className="text-blue-500 dark:text-blue-300 border-2 border-blue-400 dark:border-blue-300"
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => handleDelete(item.id)}
                      className="text-white-500 bg-black-800 white:text-white-300 border-2 border-red-400 dark:border-red-300"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Modal for Add/Update Menu Item */}
        <Modal
          title={isPost ? "Add Menu Item" : "Update Menu Item"}
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          confirmLoading={isPost ? menuCreateLoading : menuUpdateLoading}
          footer={[
            <Button key="back" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={isPost ? menuCreateLoading : menuUpdateLoading}
              onClick={handleModalOk}
            >
              {isPost ? "Add" : "Update"}
            </Button>,
          ]}
        >
          <PostForm
            form={form}
            fileList={fileList}
            onPreview={onPreview}
            onChange={onChange}
          />
        </Modal>
      </div>
    </div>
  );
};

export default PostMenu;
