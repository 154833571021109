import React from "react";
import Slider from "react-slick";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Left Arrow
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: "0px",
        zIndex: 1,
        color: "yellow",
      }}
      onClick={onClick}
    >
      <LeftOutlined />
    </div>
  );
};

// Custom Right Arrow
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        right: "0px",
        zIndex: 1,
        color: "yellow",
      }}
      onClick={onClick}
    >
      <RightOutlined />
    </div>
  );
};

const CustomSlider = ({ images, height }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <div key={index}>
          <img
            style={{ height: height }}
            alt={`slide-${index}`}
            src={`/images/menu/img/${img}`}
            className="w-full h-48 object-cover"
          />
        </div>
      ))}
    </Slider>
  );
};

export default CustomSlider;
