import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DefaultMenuImage from "../assets/image/menu.webp";
import Footer from "../common/Footer/Footer";
import Navbar from "../common/navbar/Navbar";
import useFetch from "../../hooks/useFetch";
import Slider from "../slider/Slider";
const MenuDetail = () => {
  const { restaurantId, id } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [menuItem, setMenuItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch restaurant data using useFetch hook
  const { get: fetchRestaurantDetails } = useFetch(
    `/publicApi/restaurant/${restaurantId}`,
    {
      setLoading,
      setError,
      setData: setRestaurant,
    }
  );

  useEffect(() => {
    fetchRestaurantDetails();
  }, [restaurantId]);

  useEffect(() => {
    if (restaurant) {
      // Find the specific menu item based on the provided ID
      const item = restaurant.Menus?.find(
        (item) => item.id === parseInt(id, 10)
      );
      setMenuItem(item);
    }
  }, [restaurant, id]);

  // Handle loading, error, and not found states
  if (loading) {
    return (
      <div className="text-center text-lg font-semibold mt-20">Loading...</div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-lg font-semibold mt-20">
        Failed to load restaurant details.
      </div>
    );
  }

  if (!restaurant) {
    return (
      <div className="text-center text-lg font-semibold mt-20">
        Restaurant not found
      </div>
    );
  }

  if (!menuItem) {
    return (
      <div className="text-center text-lg font-semibold mt-20">
        Menu item not found
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="p-2 dark:bg-gray-900 dark:text-white my-10">
        <div className="max-w-4xl mx-auto bg-white dark:bg-gray-900 dark: border-2 dark:border-orange-300  dark:text-white  overflow-hidden">
          {/* Display multiple images in a grid */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-4 p-4">
            {typeof menuItem.images === "string"
              ? JSON.parse(menuItem.images)
              : [] && (
                  <>
                    {menuItem.images.length > 1 ? (
                      <Slider images={menuItem.images} height={400} />
                    ) : (
                      <img
                        style={{ height: "400px" }}
                        alt={menuItem.title}
                        src={`/images/menu/img/${menuItem.images[0]}`}
                        className="w-full h-48 object-cover"
                      />
                    )}
                  </>
                )}
          </div>

          <div className="p-6">
            <h2 className="text-3xl font-bold mb-4">{menuItem.title}</h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-4">
              {menuItem.description}
            </p>
            <div className="text-gray-700 dark:text-gray-200 font-semibold">
              {menuItem.isAvailable ? (
                <span className="text-green-500">Available</span>
              ) : (
                <span className="text-red-500">Not Available</span>
              )}
            </div>
            <p className="text-xl font-bold"> {menuItem.category}</p>
            <p className="text-xl text-blue-500 font-bold">
              Price:{menuItem.price}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default MenuDetail;
