import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Input, Form, message } from "antd";
import { FaGoogle, FaTelegramPlane } from "react-icons/fa";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import PhoneNumberInput from "../phoneInput/PhoneInput";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";

const LoginForm = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const resendInterval = 20;

  const [codeLoading, setCodeLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [codeRes, setCodeRes] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [loginRes, setLoginRes] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [codeResend, setCodeResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(resendInterval);
  const [intervalId, setIntervalId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [IsCodeInputVisible, setIsCodeInputVisible] = useState([]);
  const { get: getCode, reset: resetGetCode } = useFetch("auth/get-code", {
    setLoading: setCodeLoading,
    setError: setCodeError,
    setData: setCodeRes,
  });
  const { post: login, reset: resetLogin } = usePost("auth/verify", {
    setLoading: setLoginLoading,
    setError: setLoginError,
    setData: setLoginRes,
  });

  // TELEGRAM
  useEffect(() => {
    // Define the authentication handler
    window.onTelegramAuth = (user) => {
      console.log("user-------==========-------", user);

      // Handle Telegram login
      axios
        .post(
          "https://4b5a0496-5d5a-43b0-8914-a8d3cbdb7ffc-00-m2m48nrm1xiy.janeway.replit.dev/auth/telegram",
          user,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
              "User-Agent": "CustomAgent",
            },
          }
        )
        .then((response) => {
          console.log("Logged in via Telegram", response.data.token);
          localStorage.setItem("token", response.data.token);
        })
        .catch((error) => console.error("Telegram auth failed", error));
    };

    // Load Telegram widget script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://telegram.org/js/telegram-widget.js?7";
    script.setAttribute("data-telegram-login", "Yesmed222_bot"); // Replace with your bot name
    script.setAttribute("data-size", "large");
    script.setAttribute("data-radius", "20");
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.setAttribute("data-request-access", "write");

    document.body.appendChild(script);

    // Cleanup script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // PHONE
  useEffect(() => {
    const phone = localStorage.getItem("phone");
    if (phone) form.setFieldValue("phone", phone);
  }, []);

  useEffect(() => {
    if (codeResend && resendTimer > 0) {
      const id = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      setIntervalId(id);
    } else if (resendTimer === 0) {
      setCodeResend(false);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Cleanup on unmount or reset
  }, [codeResend, resendTimer]);

  useEffect(() => {
    console.log(codeError);
    if (codeError?.message)
      message[codeError?.status || "error"](codeError?.message);
    resetGetCode();
  }, [codeError]);

  useEffect(() => {
    console.log(loginRes);
    if (loginRes?.message) message[loginRes?.status](loginRes?.message);

    if (loginRes?.status === "success" && loginRes?.token) {
      localStorage.setItem("accessToken", loginRes?.token);
      navigate("/app");
    }

    resetLogin();
  }, [loginRes]);

  useEffect(() => {
    console.log(loginError);
    if (loginError?.message) message[loginError?.status](loginError?.message);
    resetLogin();
  }, [loginError]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.role = "staff";
      console.log(values);
      await login(values);
      localStorage.setItem("role", values.role);
      setCodeResend(true);
      setResendTimer(resendInterval); // Reset timer
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCode = async () => {
    try {
      const values = await form.validateFields(["phone"]);
      localStorage.setItem("phone", values.phone);
      console.log(values);
      await getCode(values);
      setCodeResend(true);
      setCodeSent(true);
      setResendTimer(resendInterval); // Reset timer
      setIsCodeInputVisible(true); // Show code input field after phone verification
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendCode = () => {
    setCodeResend(true);
    setResendTimer(resendInterval); // Reset timer
    handleGetCode();
    // Resend OTP logic here
    // message.success("OTP resent successfully!");
  };

  const handleGoogleLogin = () => {
    window.location.href = "http://localhost:5000/auth/google";
  };
  const handleTelegramLogin = () => {
    // Redirect to your backend endpoint for Telegram authentication
    window.location.href = "http://localhost:5000/auth/telegram";
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Welcome back</h2>
        <Form form={form} layout="vertical">
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <PhoneNumberInput form={form} />
          </Form.Item>
          {codeSent && (
            <>
              <Form.Item
                name="code"
                rules={[{ required: true, message: "Please enter the code!" }]}
              >
                <Input.OTP />
              </Form.Item>
              {codeResend ? (
                <p className="resend-timer">
                  Resend OTP in {resendTimer} seconds
                </p>
              ) : (
                <Button type="link" onClick={handleResendCode}>
                  Resend OTP
                </Button>
              )}
            </>
          )}
          <Button
            type="primary"
            className="login-button primary"
            onClick={codeSent ? handleSubmit : handleGetCode}
          >
            {codeSent ? "Login" : "Verify"}
          </Button>
        </Form>
        <p className="signup-prompt">
          Don't have an account?{" "}
          <Link to="/user_registration" className="signup-link">
            Sign Up
          </Link>
        </p>
        <div className="or-divider">
          <span>Or</span>
        </div>
        <Button
          onClick={handleGoogleLogin}
          className="login-button google"
          icon={<FaGoogle />}
          size="large"
        >
          Continue with Google
        </Button>
        <Button
          onClick={handleTelegramLogin}
          className="login-button telegram"
          icon={<FaTelegramPlane />}
          size="large"
        >
          Continue with Telegram
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
