import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../assets/image/restaurant.webp";

const RestaurantCards = ({ restaurants }) => {
  const navigate = useNavigate();
  console.log("search card ---", restaurants);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      {restaurants.map((restaurant) => (
        <div
          key={restaurant.id}
          className="rounded-alg overflow-hidden  shadow-lg  transform hover:scale-95 transition-all duration-500 "
        >
          <div className="w-full h-48 bg-cover bg-center flex items-center justify-center dark:bg-gray-700 bg-gray-300">
            <img
              src={`images/restaurant/img/${restaurant?.image}` || DefaultImage}
              alt={restaurant.name}
              className="w-full h-full object-cover"
            />

            {/* <p className="text-3xl font-Poppins font-bold select-none dark:text-white text-black">
                {restaurant.name}
              </p> */}
          </div>
          <div className="p-4 dark:text-gray-300 text-gray-700">
            <h3 className="text-lg font-semibold dark:text-white text-gray-900">
              {restaurant.name}
            </h3>{" "}
            <p className="text-sm dark:text-gray-400 text-gray-600">
              {restaurant.typeOfBusiness}
            </p>
            <p className="text-sm dark:text-gray-400 text-gray-600">
              Address: {restaurant.address}
            </p>
            {restaurant.distance && (
              <p className="text-sm mt-2 font-semibold text-orange-500">
                {restaurant.distance.toFixed(2)} km away
              </p>
            )}
            <strong className="block mt-2">Menu:</strong>
            <ul className="list-disc ml-5 mt-2">
              {restaurant.Menus?.slice(0, 2).map((item, index) => (
                <li key={index}>{item.title}</li>
              ))}
            </ul>
          </div>
          <div className="p-4 flex justify-center">
            <button
              onClick={() => navigate(`/restaurant/${restaurant.resId}`)}
              className="w-full max-w-xs py-2 rounded-2xl bg-orange-400 hover:bg-orange-500 text-white dark:bg-orange-500 dark:hover:bg-orange-600"
            >
              View Details
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RestaurantCards;
