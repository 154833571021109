import React, { useState, useEffect } from "react";
import { Upload, Button, Form, Input, Select, message } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";

import {
  regions,
  subCitiesByRegion,
  locationLists,
} from "../../../services/data";
import usePost from "../../../hooks/usePost";
import { businessTypes } from "../../../services/data";
import { LocationRequest } from "../location-selector/LocationSelector";
import "./RestaurantRegistrationForm.css"; // Import custom CSS file for styling
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const RestaurantRegistration = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [registerRes, setRegisterRes] = useState(null);

  const { post: sendRegisterData, reset: resetRegisterPost } = usePost(
    "/api/restaurant",
    {
      setLoading: setRegisterLoading,
      setError: setRegisterError,
      setData: setRegisterRes,
    }
  );

  const locationOptions = locationLists.map((location) => (
    <Option key={location} value={location}>
      {location}
    </Option>
  ));

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onFinish = async (values) => {
    try {
      const values = await form.validateFields();
      console.log("post data:", values);

      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        // Handle nested objects
        if (key === "location") {
          formData.append("latitude", values.location.latitude);
          formData.append("longitude", values.location.longitude);
        } else {
          formData.append(key, values[key]);
        }
      });

      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("image", file.originFileObj);
        }
      });

      await sendRegisterData(formData);
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
      message.error(errorInfo.message);
    }
  };

  useEffect(() => {
    if (registerError) {
      message.error(registerError.message || "Registration failed");
    }
    if (registerRes?.message) {
      message.success(registerRes.message);
    }

    if (registerRes?.status === "success") {
      message.success("Restaurant registered successfully!");
      form.resetFields();
      navigate("/profile");
    }
    resetRegisterPost();
  }, [registerRes, registerError]);

  return (
    <div className="form-container">
      <Form
        form={form}
        layout="vertical"
        className="restaurant-registration-form"
        onFinish={onFinish}
      >
        <Form.Item
          label="Restaurant Image"
          name="image"
          rules={[
            {
              validator: (_, value) => {
                if (fileList.length === 0) {
                  return Promise.reject(new Error("Please upload an image!"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              beforeUpload={() => false}
            >
              {fileList.length < 1 && (
                <Button icon={<UploadOutlined />}> Upload</Button>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          label="Restaurant Name"
          name="name"
          rules={[
            { required: true, message: "Please input the Restaurant Name!" },
          ]}
          className="form-item"
        >
          <Input placeholder="Enter name" />
        </Form.Item>

        <Form.Item
          label="Type of Business"
          name="typeOfBusiness"
          rules={[{ required: true, message: "Please select a type!" }]}
          className="form-item"
        >
          <Select
            showSearch
            placeholder="Select a type"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {businessTypes.map((type) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Type The Address"
          name="address"
          rules={[{ required: true, message: "Please type the  address!" }]}
          className="form-item"
        >
          {/* <Select
            showSearch
            placeholder="Select or type an address"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
            dropdownRender={(menu) => (
              <>
                {menu}
                <Input
                  style={{ margin: "8px", width: "93%" }}
                  placeholder="Type your location"
                  onChange={(e) =>
                    form.setFieldValue("address", e.target.value)
                  } // Update form value on typing
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.stopPropagation(); // Prevent Enter key from selecting a dropdown item
                      e.preventDefault(); // Prevent default action
                    }
                  }}
                />
              </>
            )}
          >
            {locationOptions}
          </Select> */}
          <Input />
        </Form.Item>

        <Form.Item
          label="Region"
          name="region"
          rules={[{ required: true, message: "Please select a region!" }]}
          className="form-item"
        >
          <Select
            showSearch
            onChange={handleRegionChange}
            placeholder="Select a region"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {regions.map((region) => (
              <Option key={region} value={region}>
                {region}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedRegion && (
          <Form.Item
            label="Sub City"
            name="subCity"
            rules={[{ required: true, message: "Please select a subcity!" }]}
            className="form-item"
          >
            <Select
              showSearch
              placeholder="Select a subcity"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {subCitiesByRegion[selectedRegion]?.map((subCity) => (
                <Option key={subCity} value={subCity}>
                  {subCity}
                </Option>
              )) || []}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Location"
          name="location"
          rules={[{ required: true, message: "Please add your location!" }]}
          className="form-item"
        >
          <LocationRequest form={form} />
        </Form.Item>

        <Form.Item className="form-item">
          <Button type="primary" htmlType="submit" loading={registerLoading}>
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RestaurantRegistration;
