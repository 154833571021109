import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, message, Button, Card } from "antd";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import "./RestaurantProfile.css"; // Import CSS file for custom styles
import RestaurantProfileEditForm from "./RestaurantProfileEditForm";
import DefaultImage from "../../assets/image/restaurant.webp";
import { Link } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";

const RestaurantProfile = () => {
  const [form] = Form.useForm();
  const [restaurantData, setRestaurantData] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [editingRestaurant, setEditingRestaurant] = useState(null);
  const qrCodeRef1 = useRef(null);
  const qrCodeRef2 = useRef(null);
  //
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  //
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateRes, setUpdateRes] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  const qrString =
    restaurantData?.resId &&
    `${window.location.origin}/restaurant/${restaurantData?.resId}`;

  // Fetch restaurant profile data
  const { get: fetchRestaurant } = useFetch("/api/restaurant/current", {
    setLoading,
    setError,
    setData: setResponse,
  });

  // Patch restaurant profile data
  const { patch: updateRestaurant, reset: resetUpdate } = usePatch(
    `/api/restaurant/${restaurantData?.id}`,
    {
      setLoading: setUpdateLoading,
      setError: setUpdateError,
      setData: setUpdateRes,
    }
  );

  useEffect(() => {
    fetchRestaurant();
  }, []);

  useEffect(() => {
    if (response && Array.isArray(response) && response.length > 0) {
      console.log("--------------restaurants", response[0]);
      // Access the first restaurant object
      setRestaurantData(response[0]);
    } else {
      console.error("No valid restaurant data found in the response");
      setRestaurantData({}); // Reset to an empty object if no valid data
    }
  }, [response]);

  useEffect(() => {
    if (updateRes?.message) message.success(updateRes.message);
    if (updateError?.message) message.error(updateError.message);
    if (updateRes?.status === "success") {
      setIsModalVisible(false);
      fetchRestaurant();
    }
    resetUpdate();
  }, [updateRes, updateError]);

  const handleUpdate = () => {
    setIsUpdate(true);
    setEditingRestaurant(restaurantData);
    form.setFieldsValue({
      name: restaurantData?.name,
      typeOfBusiness: restaurantData?.typeOfBusiness,
      address: restaurantData?.address,
      region: restaurantData?.region,
      subCity: restaurantData?.subCity,
      image: restaurantData?.image,
    });

    // Initialize the image file list for display in the form
    setFileList(
      restaurantData?.image
        ? [
            {
              uid: "-1",
              name: restaurantData.image,
              status: "done",
              url: `images/restaurant/img/${restaurantData.image}`,
            },
          ]
        : []
    );

    // Set the location initially if it exists
    if (restaurantData?.latitude && restaurantData?.longitude) {
      form.setFieldsValue({
        location: {
          latitude: restaurantData.latitude,
          longitude: restaurantData.longitude,
        },
      });
    }

    setIsModalVisible(true);
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const handleModalCancel = () => setIsModalVisible(false);

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        // Handle nested objects
        if (key === "location") {
          formData.append("latitude", values.location.latitude);
          formData.append("longitude", values.location.longitude);
        } else {
          formData.append(key, values[key]);
        }
      });
      fileList.forEach((file) => {
        if (file.originFileObj) {
          formData.append("image", file.originFileObj);
        }
      });

      if (isUpdate) {
        await updateRestaurant(formData);
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };
  const downloadQRCode = (qrRef, fileName) => {
    const canvas = qrRef.current.querySelector("canvas");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
  };

  return (
    <div className="p-2 my-2 dark:bg-gray-900 dark:text-white">
      <div className="max-w-7xl p-2 my-2 mx-auto bg-white dark:bg-gray-800 rounded-alg border-2 border-black-400 dark:border-black-300 overflow-hidden flex justify-center">
        {/* Profile Header */}
        <div className="flex flex-col md:flex-row items-center justify-center">
          {/*  <div
          className="relative bg-cover bg-center w-64 h-64 text-white flex flex-col items-center justify-center shadow-lg"
          style={{
            backgroundImage: `url(${DefaultImage})`,
          }}
          >
          <h2 className="text-xl font-bold mb-2">{restaurantData?.name}</h2>
            <p className="text-sm mb-2">{restaurantData?.phone}</p> */}
          {/* <div ref={qrCodeRef1} className="p-4 bg-white">
              {qrString && <QRCodeCanvas value={qrString} size={150} />}
            </div>
            <Button
              onClick={() => downloadQRCode(qrCodeRef1, "restaurant_qr.png")}
              className="mt-4"
            >
              Download QR
            </Button>
          </div> */}

          {/* Second QR Code (regular) */}
          <div className="p-6 shadow-lg my-6">
            <div ref={qrCodeRef2} className="p-4 bg-white">
              {qrString && <QRCodeCanvas value={qrString} size={200} />}
            </div>
            <Button
              onClick={() => downloadQRCode(qrCodeRef2, "menu_qr.png")}
              className="mt-4 text-blue"
            >
              Download Your QR
            </Button>
          </div>
          <div
            style={{ height: 270, width: 270 }}
            className="w-32 h-32 md:w-48 md:h-48 rounded-full overflow-hidden"
          >
            <img
              alt={restaurantData?.name}
              src={
                `images/restaurant/img/${restaurantData?.image}` || DefaultImage
              }
              className="w-full h-full object-cover"
            />
          </div>
          <div className="mt-6 md:mt-0 md:ml-8 flex-1 text-center shadow-lg md:text-left">
            <div className="flex flex-col md:flex-row items-center md:items-start mb-4">
              <h2 className="text-3xl font-semibold mr-0 md:mr-4">
                {restaurantData?.name}
              </h2>
              {restaurantData?.name ? (
                <Button
                  onClick={handleUpdate}
                  className="border border-gray-300 px-4 py-1 rounded text-sm font-medium mt-2 md:mt-0"
                >
                  Edit Profile
                </Button>
              ) : (
                <Button className="border border-gray-300 px-4 py-1 rounded text-sm font-medium mt-2 md:mt-0">
                  <Link to={"/restaurant_registration"}> Register </Link>
                </Button>
              )}
            </div>

            <div className="text-center md:text-left">
              <p className="mb-1">
                <strong>Type of Business:</strong>{" "}
                {restaurantData?.typeOfBusiness}
              </p>
              <p className="mb-1">
                <strong>Address:</strong> {restaurantData?.address}
              </p>
              <p className="mb-1">
                <strong>Region:</strong> {restaurantData?.region}
              </p>
              <p className="mb-1">
                <strong>SubCity:</strong> {restaurantData?.subCity}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Profile Modal */}
      <Modal
        title="Edit Restaurant Profile"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        confirmLoading={updateLoading}
      >
        <RestaurantProfileEditForm
          form={form}
          onPreview={onPreview}
          onChange={onChange}
          fileList={fileList}
          handleModalOk={handleModalOk}
        />
      </Modal>
    </div>
  );
};

export default RestaurantProfile;
