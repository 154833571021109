import React, { useContext, useEffect, useState } from "react";
import PostMenu from "../components/profile/post/PostMenu";
import RestaurantProfile from "../components/profile/restaurant-info/RestaurantProfile";
import Navbar from "../components/common/navbar/Navbar";
import Footer from "../components/common/Footer/Footer";
import useFetch from "../hooks/useFetch";
import { AuthContext } from "../context/AuthContext";
import NotFound from "./NotFound";

const ProfilePage = () => {
  const { user, setUser } = useContext(AuthContext); // Use user context

  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [authRes, setAuthRes] = useState(null);

  const { get: getAuth } = useFetch("auth/authenticate", {
    setLoading: setAuthLoading,
    setError: setAuthError,
    setData: setAuthRes,
  });

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    if (authRes?.authenticated) {
      setUser(true); // Update the user context
    } else {
      setUser(false); // Update the user context
    }
  }, [authRes, setUser]);

  // if (!user) {
  //   return <NotFound />;
  // }

  return (
    <>
      <Navbar />
      <RestaurantProfile />
      <PostMenu />
      <Footer />
    </>
  );
};

export default ProfilePage;
