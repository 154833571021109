import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white pb-10 pt-14 px-6 dark:bg-gray-950 dark:text-gray-300">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <h3 className="text-lg font-bold mb-4 text-orange-200 dark:text-orange-400">
            Contact Us
          </h3>
          <ul className="space-y-2">
            <li>Email: Huzeyfa74@gmail.com</li>
            <li>Phone: +251 962 489 700</li>
            <li>Address: A.A, City, Ethiopia</li>
          </ul>
        </div>

        <div className="text-center md:mt-8 my-10">
          <h3 className="flex justify-center space-x-4 text-2xl font-bold mb-4 text-orange-500 dark:text-orange-300">
            <IoRestaurant /> <span>Huzi Menu</span>
          </h3>
          <p className="mb-4">
            Bringing the best food closer to you. An ally you can trust!
          </p>
          <div className="flex justify-center space-x-6">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-orange-400 dark:hover:text-orange-300"
            >
              <FaFacebookF size={24} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-orange-400 dark:hover:text-orange-300"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-orange-400 dark:hover:text-orange-300"
            >
              <FaInstagram size={24} />
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-orange-400 dark:hover:text-orange-300"
            >
              <FaLinkedinIn size={24} />
            </a>
          </div>
        </div>

        <div className="text-right">
          <h3 className="text-lg font-bold mb-4 text-orange-200 dark:text-orange-400">
            About Us
          </h3>
          <ul className="space-y-2">
            <li>
              <a
                className="hover:underline hover:underline-offset-4 hover:decoration-1 dark:hover:text-orange-300"
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
              >
                Our Story
              </a>
            </li>
            <li>
              <a
                className="hover:underline hover:underline-offset-4 hover:decoration-1 dark:hover:text-orange-300"
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </li>
            <li>
              <a
                className="hover:underline hover:underline-offset-4 hover:decoration-1 dark:hover:text-orange-300"
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="mt-20 border-t border-gray-700 pt-4 text-center dark:border-gray-600">
        <p className="text-sm text-gray-400 dark:text-gray-500">
          &copy; {new Date().getFullYear()} Company Name. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
