import React from "react";
import { Upload, Button, Form, Input, Select, Switch } from "antd";
import ImgCrop from "antd-img-crop";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

const fieldsMenu = [
  { label: "Title", name: "title", type: "text" },
  {
    label: "Category",
    name: "category",
    type: "select",
    options: [
      "Appetizers/Starters",
      "Main Course",
      "Lunch Special",
      "Side Dishes",
      "Dessert",
      "Beverage",
      "Kids' Menu",
      "Special",
      "Juice",
    ],
  },
  { label: "Price", name: "price", type: "number" },
  { label: "Description", name: "description", type: "textarea" },
  // Availability Field
  { label: "Availability", name: "isAvailable", type: "switch" },
];

const PostForm = ({ form, handleModalOk, onChange, onPreview, fileList }) => {
  return (
    <Form onFinish={handleModalOk} form={form} layout="vertical">
      {fieldsMenu?.map((field) => (
        <Form.Item
          key={field?.name}
          label={field?.label}
          name={field?.name}
          valuePropName={field.type === "switch" ? "checked" : "value"}
          rules={[
            {
              required: field.type !== "switch",
              message: `Please input your ${field?.label?.toLowerCase()}!`,
            },
          ]}
        >
          {field.type === "textarea" ? (
            <Input.TextArea />
          ) : field.type === "select" ? (
            <Select>
              {field.options.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          ) : field.type === "switch" ? (
            <Switch />
          ) : (
            <Input type={field.type} />
          )}
        </Form.Item>
      ))}

      <Form.Item label="Post image" name="images">
        <ImgCrop rotationSlider>
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
            beforeUpload={() => false}
            multiple
          >
            {fileList.length < 3 && (
              <Button icon={<UploadOutlined />}> Upload</Button>
            )}
          </Upload>
        </ImgCrop>
      </Form.Item>
    </Form>
  );
};

export default PostForm;
