import React, { useState } from "react";
import { EnvironmentOutlined } from "@ant-design/icons";
import { FiSearch } from "react-icons/fi";

const SearchComponent = ({ onSearch, onNearest, setUserLocation }) => {
  const [query, setQuery] = useState("");

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    onSearch(e.target.value);
  };

  const handleLocationClick = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      setUserLocation({ latitude, longitude });
      onNearest({ latitude, longitude });
    });
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-between md:mx-20 gap-4 p-6 bg-transparent">
      <div className="relative w-full max-w-2xl">
        <FiSearch
          className="absolute left-4 top-1/2 transform -translate-y-1/2 text-orange-500 dark:text-orange-300"
          size={20}
        />
        <input
          placeholder="Search by name or food"
          value={query}
          onChange={handleSearchChange}
          className="shadow-lg p-3 w-full rounded-alg pl-14 border-2 border-orange-500 dark:border-orange-300 bg-white dark:bg-gray-700 text-gray-900 dark:text-white focus:border-orange-500 transition-all duration-500"
        />
      </div>

      <button
        onClick={handleLocationClick}
        className="bg-gradient-to-r from-orange-500 to-orange-700 dark:from-orange-500 dark:to-orange-600 dark:hover:from-orange-600 dark:hover:to-orange-500 text-white p-3 md:px-8 rounded-alg"
      >
        <EnvironmentOutlined className="mr-2" /> Nearest Restaurant
      </button>
    </div>
  );
};

export default SearchComponent;
