export const regions = [
  "Addis Ababa",
  "Afar",
  "Amhara",
  "Benishangul-Gumuz",
  "Dire Dawa",
  "Gambela",
  "Harari",
  "Oromia",
  "Sidama",
  "Somali",
  "Southern Nations, Nationalities, and Peoples' Region (SNNPR)",
  "Tigray",
  "Metekel",
  "Kamashi",
  "Assosa",
  "Itang",
  "Jarar",
  "Nogob",
  "Shabelle",
  "Fafan",
  "Liben",
  "Afder",
  "Jijiga",
  "Guji",
  "Bale",
  "West Arsi",
  "West Hararghe",
  "West Shewa",
  "East Shewa",
  "East Hararghe",
  "Arsi",
  "Borena",
  "Hadiya",
  "Kembata Tembaro",
  "Silte",
  "Gedeo",
  "Dawro",
  "Bench Maji",
  "South Omo",
  "Gamo Gofa",
  "Kontta",
  "Kaffa",
  "Sheka",
  "Majang",
  "Nuwer",
];

export const subCitiesByRegion = {
  "Addis Ababa": [
    "Kolfe Keranio",
    "Arada",
    "Akaki Kaliti",
    "Addis Ketema",
    "Bole",
    "Gullele",
    "Kirkos",
    "Lideta",
    "Nefas Silk",
    "Yeka",
  ],
  Afar: ["Asayita", "Dubti", "Mille", "Awash", "Semera"],
  Amhara: [
    "Bahir Dar",
    "Gondar",
    "Dessie",
    "Debre Birhan",
    "Debre Markos",
    "Woldia",
    "Kombolcha",
  ],
  "Benishangul-Gumuz": ["Assosa", "Bambasi", "Gilgil Beles", "Kamashi"],
  "Dire Dawa": ["Dire Dawa"],
  Gambela: ["Gambela", "Abobo", "Itang", "Mengeshi"],
  Harari: ["Harar"],
  Oromia: [
    "Adama",
    "Jimma",
    "Shashemene",
    "Bishoftu",
    "Ambo",
    "Nekemte",
    "Asella",
  ],
  Somali: ["Jigjiga", "Kebri Dahar", "Degehabur", "Gode", "Werder"],
  "Southern Nations, Nationalities, and Peoples' Region (SNNPR)": [
    "Hawassa",
    "Wolaita Sodo",
    "Arba Minch",
    "Hosaena",
    "Butajira",
    "Dilla",
  ],
  Tigray: ["Mekelle", "Adigrat", "Axum", "Shire", "Alamata"],
  // Add other regions and their subcities here
};
export const businessTypes = [
  "Hotel",
  "Restaurant",
  "Bar",
  "Takeaway",
  "Pizzeria",

  "Event",
  "Brewery",
  "Canteen",
  "Sports club",
  "Sports complex",

  "Bistro",
  "Brasserie",
  "Sandwich shop",
  "Café",
  "Cafeteria",
  "Cocktail bar",

  "Ice cream parlor",
  "Food truck",

  "Popup",
  "Snackbar",

  "Caterer",
  "Bakery",
  "Butcher shop",
  "Tapas bar",
  "Lounge bar",
];
// services/data.js
// src/services/data.js
export const restaurants = [
  {
    id: 1,
    name: "Restaurant A",
    location: "City X",
    longitude: 40.7128,
    latitude: -74.006,
    menu: [{ foodName: "Pizza" }],
  },
  {
    id: 2,
    name: "Restaurant B",
    location: "City Y",
    longitude: 34.0522,
    latitude: -118.2437,

    menu: [{ foodName: "Burger" }],
  },
  {
    id: 3,
    name: "Restaurant C",
    location: "City Z",
    longitude: 48.8566,
    latitude: 2.3522,
    menu: [{ foodName: "Pasta" }],
  },
  {
    id: 4,
    name: "Restaurant D",
    location: "City X",
    longitude: 37.7749,
    latitude: -122.4194,
    menu: [{ foodName: "Sushi" }],
  },
  {
    id: 5,
    name: "Restaurant E",
    location: "City Z",
    longitude: 51.5074,
    latitude: -0.1278,

    menu: [{ foodName: "Salad" }],
  },
  {
    id: 6,
    name: "Restaurant F",
    location: "City Y",
    longitude: 40.7306,
    latitude: -73.9352,
    menu: [{ foodName: "Tacos" }],
  },
  {
    id: 7,
    name: "Restaurant G",
    location: "City X",
    longitude: 34.0522,
    latitude: -118.2437,
    menu: [{ foodName: "Steak" }],
  },
  {
    id: 8,
    name: "Restaurant H",
    location: "City Z",
    longitude: 37.7749,
    latitude: -122.4194,
    menu: [{ foodName: "Sandwich" }],
  },
  {
    id: 9,
    name: "Restaurant I",
    location: "City Y",
    longitude: 51.5074,
    latitude: -0.1278,
    menu: [{ foodName: "Soup" }],
  },
  {
    id: 10,
    name: "Restaurant J",
    location: "City X",
    longitude: 48.8566,
    latitude: 2.3522,
    menu: [{ foodName: "Ice Cream" }],
  },
  {
    id: 11,
    name: "Restaurant K",
    location: "City Y",
    longitude: 34.0522,
    latitude: -118.2437,
    menu: [{ foodName: "Noodles" }],
  },
  {
    id: 12,
    name: "Restaurant L",
    location: "City Z",
    longitude: 40.7128,
    latitude: -74.006,
    menu: [{ foodName: "Fries" }],
  },
];

export const restaurantss = [
  {
    id: 1,
    email: "contact@restaurantA.com",
    name: "Restaurant A",
    location: "City X",
    longitude: 40.7128,
    latitude: -74.006,
    menu: [
      {
        id: 1,
        title: "Spaghetti Carbonara",
        category: "Main Course",
        price: 12.99,
        description:
          "Classic Italian pasta dish with creamy sauce and pancetta.",
        image: "https://example.com/images/spaghetti-carbonara.jpg",
        restaurantId: 1,
      },
      {
        id: 2,
        title: "Sandwich",
        category: "Lunch Specials",
        price: 8.99,
        description: "Delicious sandwich with fresh ingredients.",
        image: "https://example.com/images/sandwich.jpg",
        restaurantId: 1,
      },
    ],
  },
  {
    id: 2,
    email: "contact@restaurantB.com",
    name: "Restaurant B",
    location: "City Y",
    longitude: 34.0522,
    latitude: -118.2437,
    menu: [
      {
        id: 1,
        title: "Burger",
        category: "Main Course",
        price: 10.99,
        description: "Juicy beef burger with cheese and lettuce.",
        image: "https://example.com/images/burger.jpg",
        restaurantId: 2,
      },
      {
        id: 2,
        title: "Fries",
        category: "Side Dishes",
        price: 3.99,
        description: "Crispy golden fries.",
        image: "https://example.com/images/fries.jpg",
        restaurantId: 2,
      },
    ],
  },
  {
    id: 3,
    email: "contact@restaurantC.com",
    name: "Restaurant C",
    location: "City Z",
    longitude: 48.8566,
    latitude: 2.3522,
    menu: [
      {
        id: 1,
        title: "Pasta",
        category: "Main Course",
        price: 11.99,
        description: "Delicious pasta with tomato sauce.",
        image: "https://example.com/images/pasta.jpg",
        restaurantId: 3,
      },
      {
        id: 2,
        title: "Salad",
        category: "Appetizers/Starters",
        price: 6.99,
        description: "Fresh garden salad.",
        image: "https://example.com/images/salad.jpg",
        restaurantId: 3,
      },
    ],
  },
  {
    id: 4,
    email: "contact@restaurantD.com",
    name: "Restaurant D",
    location: "City X",
    longitude: 37.7749,
    latitude: -122.4194,
    menu: [
      {
        id: 1,
        title: "Sushi",
        category: "Main Course",
        price: 14.99,
        description: "Assorted sushi platter.",
        image: "https://example.com/images/sushi.jpg",
        restaurantId: 4,
      },
      {
        id: 2,
        title: "Miso Soup",
        category: "Appetizers/Starters",
        price: 4.99,
        description: "Traditional Japanese miso soup.",
        image: "https://example.com/images/miso-soup.jpg",
        restaurantId: 4,
      },
    ],
  },
  {
    id: 5,
    email: "contact@restaurantE.com",
    name: "Restaurant E",
    location: "City Z",
    longitude: 51.5074,
    latitude: -0.1278,
    menu: [
      {
        id: 1,
        title: "Salad",
        category: "Appetizers/Starters",
        price: 6.99,
        description: "Fresh garden salad.",
        image: "https://example.com/images/salad.jpg",
        restaurantId: 5,
      },
      {
        id: 2,
        title: "Soup",
        category: "Appetizers/Starters",
        price: 5.99,
        description: "Warm and comforting soup.",
        image: "https://example.com/images/soup.jpg",
        restaurantId: 5,
      },
    ],
  },
  {
    id: 6,
    email: "contact@restaurantF.com",
    name: "Restaurant F",
    location: "City Y",
    longitude: 40.7306,
    latitude: -73.9352,
    menu: [
      {
        id: 1,
        title: "Tacos",
        category: "Main Course",
        price: 9.99,
        description: "Spicy beef tacos with salsa.",
        image: "https://example.com/images/tacos.jpg",
        restaurantId: 6,
      },
      {
        id: 2,
        title: "Nachos",
        category: "Appetizers/Starters",
        price: 7.99,
        description: "Cheesy nachos with jalapenos.",
        image: "https://example.com/images/nachos.jpg",
        restaurantId: 6,
      },
    ],
  },
  {
    id: 7,
    email: "contact@restaurantG.com",
    name: "Restaurant G",
    location: "City X",
    longitude: 34.0522,
    latitude: -118.2437,
    menu: [
      {
        id: 1,
        title: "Steak",
        category: "Main Course",
        price: 19.99,
        description: "Grilled steak with garlic butter.",
        image: "https://example.com/images/steak.jpg",
        restaurantId: 7,
      },
      {
        id: 2,
        title: "Mashed Potatoes",
        category: "Side Dishes",
        price: 4.99,
        description: "Creamy mashed potatoes.",
        image: "https://example.com/images/mashed-potatoes.jpg",
        restaurantId: 7,
      },
    ],
  },
  {
    id: 8,
    email: "contact@restaurantH.com",
    name: "Restaurant H",
    location: "City Z",
    longitude: 37.7749,
    latitude: -122.4194,
    menu: [
      {
        id: 1,
        title: "Sandwich",
        category: "Lunch Specials",
        price: 8.99,
        description: "Delicious sandwich with fresh ingredients.",
        image: "https://example.com/images/sandwich.jpg",
        restaurantId: 8,
      },
      {
        id: 2,
        title: "Chips",
        category: "Side Dishes",
        price: 2.99,
        description: "Crispy potato chips.",
        image: "https://example.com/images/chips.jpg",
        restaurantId: 8,
      },
    ],
  },
  {
    id: 9,
    email: "contact@restaurantI.com",
    name: "Restaurant I",
    location: "City Y",
    longitude: 51.5074,
    latitude: -0.1278,
    menu: [
      {
        id: 1,
        title: "Soup",
        category: "Appetizers/Starters",
        price: 5.99,
        description: "Warm and comforting soup.",
        image: "https://example.com/images/soup.jpg",
        restaurantId: 9,
      },
      {
        id: 2,
        title: "Breadsticks",
        category: "Side Dishes",
        price: 3.99,
        description: "Garlic breadsticks.",
        image: "https://example.com/images/breadsticks.jpg",
        restaurantId: 9,
      },
    ],
  },
  {
    id: 10,
    email: "contact@restaurantJ.com",
    name: "Restaurant J",
    location: "City X",
    longitude: 48.8566,
    latitude: 2.3522,
    menu: [
      {
        id: 1,
        title: "Ice Cream",
        category: "Desserts",
        price: 4.99,
        description: "Creamy vanilla ice cream.",
        image: "https://example.com/images/ice-cream.jpg",
        restaurantId: 10,
      },
      {
        id: 2,
        title: "Brownie",
        category: "Desserts",
        price: 3.99,
        description: "Chocolate brownie with nuts.",
        image: "https://example.com/images/brownie.jpg",
        restaurantId: 10,
      },
    ],
  },
  {
    id: 11,
    email: "contact@restaurantK.com",
    name: "Restaurant K",
    location: "City Y",
    longitude: 34.0522,
    latitude: -118.2437,
    menu: [
      {
        id: 1,
        title: "Noodles",
        category: "Main Course",
        price: 9.99,
        description: "Stir-fried noodles with vegetables.",
        image: "https://example.com/images/noodles.jpg",
        restaurantId: 11,
      },
      {
        id: 2,
        title: "Spring Rolls",
        category: "Appetizers/Starters",
        price: 5.99,
        description: "Crispy spring rolls with dipping sauce.",
        image: "https://example.com/images/spring-rolls.jpg",
        restaurantId: 11,
      },
    ],
  },
  {
    id: 12,
    email: "contact@restaurantL.com",
    name: "Restaurant L",
    location: "City Z",
    longitude: 37.7749,
    latitude: -122.4194,
    menu: [
      {
        id: 1,
        title: "Pancakes",
        category: "Desserts",
        price: 7.99,
        description: "Fluffy pancakes with syrup.",
        image: "https://example.com/images/pancakes.jpg",
        restaurantId: 12,
      },
      {
        id: 2,
        title: "Waffles",
        category: "Desserts",
        price: 8.99,
        description: "Crispy waffles with fruit topping.",
        image: "https://example.com/images/waffles.jpg",
        restaurantId: 12,
      },
    ],
  },
];
export const locationLists = [
  "Arada",
  "Arat Kilo",
  "Asko",
  "Ayat",
  "Bole",
  "Bole Bulbula",
  "CMC",
  "Gofa",
  "Gulele",
  "Haya Hulet",
  "Jemo",
  "Kazanchis",
  "Kolfe",
  "Kotebe",
  "Lafto",
  "Lebu",
  "Lideta",
  "Megenagna",
  "Meknisa",
  "Meskel Square",
  "Mexico",
  "Old Airport",
  "Piazza",
  "Qality",
  "Sar Bet",
  "Shiro Meda",
  "Sidist Kilo",
  "Summit",
  "Urael",
  "Wello Sefer",
  "Yeka",
  "Addis Ketema",
  "Akaki Kality",
  "Nifas Silk-Lafto",
  "Bole Medhane Alem",
  "Gurd Shola",
  "Gerji",
  "Gelan Condominium",
  "Gotera",
  "Zenebework",
  "Alem Bank",
  "Bambis",
  "Kera",
  "Saris",
  "Dembel City Center",
  "Sarbet",
  "Senga Tera",
  "Jemo 1",
  "Jemo 2",
  "Ferensay Legasion",
  "Shola",
  "Mekanisa",
  "Torhailoch",
  "Signal",
  "Kebena",
  "Gelan Condos",
  "Ras Mekonnen Bridge",
  "Cherkos",
  "Lamberet",
  "Asko",
  "Figa",
  "Minilik Square",
  "Dej. Wube Sefer",
  "Asmera Road",
  "Adisu Gebeya",
  "Gofa Camp",
  "Tegbareid",
  "Beherawi",
  "Meshualekia",
  "Kebena",
  "Gotera",
  "Hayat",
  "Urael",
  "Gurd Shola",
  "Gullale",
  "Megenagna",
  "Saris",
  "NOC",
  "Bulbula",
  "Addisu Gebeya",
];
// import React, { useEffect } from "react";
// import axios from "axios";

// const Login = () => {
//   useEffect(() => {
//     // Initialize Telegram Login Widget
//     window.TelegramLoginWidget = {
//       dataOnauth: (user) => {
//         console.log("user-------==========-------", user);

//         axios
//           .post(
//             "https://da6a-102-218-50-170.ngrok-free.app/auth/telegram",
//             user,
//             {
//               headers: {
//                 "ngrok-skip-browser-warning": "true",
//                 "User-Agent": "CustomAgent",
//               },
//             }
//           )
//           .then((response) => {
//             console.log("Logged in via Telegram", response.data.token);
//             localStorage.setItem("token", response.data.token);
//           })
//           .catch((error) => console.error("Telegram auth failed", error));
//       },
//     };

//     // Load Telegram widget script
//     const script = document.createElement("script");
//     script.src = "https://telegram.org/js/telegram-widget.js?7";
//     script.setAttribute("data-telegram-login", "Yesmed222_bot"); // Remove "@"
//     script.setAttribute("data-size", "large");
//     // script.setAttribute("data-auth-url", "http://localhost:5000/auth/telegram");
//     script.setAttribute(
//       "data-auth-url",
//       "http://localhost:5000/auth/proxy/telegram"
//     );

//     script.setAttribute("data-request-access", "write");
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   const handleGoogleLogin = () => {
//     window.location.href = "http://localhost:5000/auth/google";
//   };

//   return (
//     <div>
//       <h2>Login</h2>
//       <button onClick={handleGoogleLogin}>Login with Google</button>
//       <div id="telegram-login"></div>
//     </div>
//   );
// };

// export default Login;