import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { MdOutlineMenu } from "react-icons/md";
import { GrLanguage } from "react-icons/gr";
import { FaRegUser } from "react-icons/fa";
import { IoMoon, IoRestaurant, IoSunny } from "react-icons/io5";
import logo from "../../assets/image/logo.png";
import { ThemeContext } from "../../../context/ThemeContext";
import { FaSignInAlt } from "react-icons/fa";
import useFetch from "../../../hooks/useFetch";
import { AuthContext } from "../../../context/AuthContext";
import { FaHome } from "react-icons/fa";

const Navbar = () => {
  const navigate = useNavigate();

  const { theme, toggleTheme } = useContext(ThemeContext);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const location = useLocation();
  const { user, setUser } = useContext(AuthContext); // Use user context

  //
  const [authLoading, setAuthLoading] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [authRes, setAuthRes] = useState(null);

  const { get: getAuth } = useFetch("auth/authenticate", {
    setLoading: setAuthLoading,
    setError: setAuthError,
    setData: setAuthRes,
  });

  useEffect(() => {
    getAuth();
  }, []);
  useEffect(() => {
    if (authRes?.authenticated) {
      setUser(true); // Update the user context
    } else {
      setUser(false); // Update the user context
    }
  }, [authRes, setUser]);

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setMobileDrawerOpen(false);
  }, [location]);

  return (
    <nav className="sticky top-0 z-50 py-4 backdrop-blur-lg border-b border-neutral-700/80 dark:border-neutral-700">
      <div className="container px-4 mx-auto relative lg:text-sm">
        <div className="flex justify-between items-center">
          <div className="flex items-center flex-shrink-0">
            <GrLanguage className="mr-5 text-lg text-orange-300 dark:text-orange-400" />
            <Link to="/" className="flex items-center">
              <img className="h-10 w-10 mr-2" src={logo} alt="Logo" />
              <span className="text-xl tracking-tight text-black dark:text-white">
                HUZI MENU
              </span>
            </Link>
          </div>

          <div className="hidden lg:flex ml-14 space-x-12">
            <Link
              to="/"
              className="hover:text-orange-500 text-base text-black dark:text-white"
            >
              <FaHome />
            </Link>

            <Link
              to="/login"
              className="hover:text-orange-500 text-base text-black dark:text-white"
            >
              {/* Login<FaSignInAlt></FaSignInAlt> */}
            </Link>
          </div>

          <div className="hidden lg:flex justify-center space-x-3 items-center">
            {user ? (
              <>
                <Link
                  to="/profile"
                  className="p-2 border-2 mr-20 ml-10 hover:border-orange-500 rounded-full border-black dark:border-white"
                >
                  <FaRegUser className="text-xl text-black dark:text-white" />
                </Link>{" "}
                <Link
                  to="/payment"
                  className="py-2 px-3 rounded-md bg-gradient-to-r from-orange-500 to-orange-800 text-white"
                >
                  Payment Package
                </Link>
              </>
            ) : (
              <>
                {" "}
                <Link
                  to="/payment"
                  className="py-2 px-3 rounded-md bg-gradient-to-r from-orange-500 to-orange-800 text-white"
                >
                  Payment Package
                </Link>
                <Link
                  to="/login"
                  className="py-2 px-3 border rounded-md border-black dark:border-white text-black dark:text-white"
                >
                  Log In
                </Link>
                <Link
                  to="/user_registration"
                  className="bg-gradient-to-r from-orange-500 to-orange-800 py-2 px-3 rounded-md text-white"
                >
                  Sign Up
                </Link>
              </>
            )}
            <div className="flex items-center space-x-4">
              <button
                onClick={toggleTheme}
                className="text-2xl focus:outline-none transition-transform transform duration-500 dark:rotate-0 rotate-180"
              >
                {theme === "dark" ? (
                  <IoSunny className="text-yellow-300" />
                ) : (
                  <IoMoon className="rotate-180" />
                )}
              </button>
            </div>
          </div>

          <div className="lg:hidden md:flex justify-end items-center space-x-4 text-xl">
            <button
              onClick={toggleTheme}
              className="text-xl focus:outline-none transition-transform transform duration-500 dark:rotate-0 rotate-180"
            >
              {theme === "dark" ? (
                <IoSunny className="text-yellow-300" />
              ) : (
                <IoMoon className="rotate-180" />
              )}
            </button>
            <button
              className={`"text-xl focus:outline-none transition-transform transform duration-500 ${
                mobileDrawerOpen ? "rotate-0" : "rotate-180"
              }`}
              onClick={toggleNavbar}
            >
              {mobileDrawerOpen ? (
                <IoRestaurant />
              ) : (
                <MdOutlineMenu className="rotate-180" />
              )}
            </button>
          </div>
        </div>

        {mobileDrawerOpen && (
          <div className="fixed right-0 z-20 w-full h-fit p-20 flex flex-col justify-center items-center lg:hidden transition-all duration-500 bg-gray-300 dark:bg-neutral-900">
            <div className="flex flex-col space-y-10 mb-20 items-center text-center justify-center">
              <Link
                to="/"
                className="hover:text-orange-500 text-base text-black dark:text-white"
              >
                <FaHome />
              </Link>

              {/* <Link
                to="/restaurant_registration"
                className="hover:text-orange-500 text-base text-black dark:text-white"
              >
                Register a Business
              </Link> */}
            </div>

            <div className="flex mt-10 space-x-6">
              {user ? (
                <>
                  <Link
                    to="/profile"
                    className="flex items-center justify-center space-x-3 py-2 px-3 border rounded-md border-black dark:border-white"
                  >
                    <FaRegUser className="text-xl text-black dark:text-white" />
                    <span>Profile</span>
                  </Link>
                  <Link
                    to="/payment"
                    className="py-2 px-3 rounded-md bg-gradient-to-r from-orange-500 to-orange-800 text-white"
                  >
                    Payment Package
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="py-2 px-3 border rounded-md border-black dark:border-white text-black dark:text-white"
                  >
                    Log In
                  </Link>
                  <Link
                    to="/user_registration"
                    className="py-2 px-3 rounded-md bg-gradient-to-r from-orange-500 to-orange-800 text-white"
                  >
                    Sign Up
                  </Link>
                  <Link
                    to="/payment"
                    className="py-2 px-3 rounded-md bg-gradient-to-r from-orange-500 to-orange-800 text-white"
                  >
                    Payment Package
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
