import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Input, Form, message } from "antd";
import { FaGoogle, FaTelegramPlane } from "react-icons/fa";
import "../login-form/Login.css";
import { Link, useNavigate } from "react-router-dom";
import PhoneNumberInput from "../phoneInput/PhoneInput";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";

const UserRegistration = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const resendInterval = 20;
  const [roles, setRoles] = useState([]);

  const [codeLoading, setCodeLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [codeRes, setCodeRes] = useState(null);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [registerRes, setRegisterRes] = useState(null);
  const [codeSent, setCodeSent] = useState(false);
  const [codeResend, setCodeResend] = useState(false);
  const [resendTimer, setResendTimer] = useState(resendInterval);
  const [intervalId, setIntervalId] = useState(null);
  const [IsCodeInputVisible, setIsCodeInputVisible] = useState(false);
  const { get: getCode, reset: resetGetCode } = useFetch("auth/get-code", {
    setLoading: setCodeLoading,
    setError: setCodeError,
    setData: setCodeRes,
  });
  const { post: register, reset: resetRegister } = usePost("auth/register", {
    setLoading: setRegisterLoading,
    setError: setRegisterError,
    setData: setRegisterRes,
  });

  // TELEGRAM
  useEffect(() => {
    window.onTelegramAuth = (user) => {
      console.log("Telegram user:", user);

      axios
        .post("https://example.com/auth/telegram", user)
        .then((response) => {
          console.log("Logged in via Telegram", response.data.token);
          localStorage.setItem("token", response.data.token);
        })
        .catch((error) => console.error("Telegram auth failed", error));
    };

    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?7";
    script.setAttribute("data-telegram-login", "Yesmed222_bot");
    script.setAttribute("data-size", "large");
    script.setAttribute("data-onauth", "onTelegramAuth(user)");
    script.setAttribute("data-request-access", "write");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // PHONE
  useEffect(() => {
    const phone = localStorage.getItem("phone");
    if (phone) form.setFieldValue("phone", phone);
  }, []);

  useEffect(() => {
    if (codeResend && resendTimer > 0) {
      const id = setInterval(() => {
        setResendTimer((prev) => prev - 1);
      }, 1000);
      setIntervalId(id);
    } else if (resendTimer === 0) {
      setCodeResend(false);
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [codeResend, resendTimer]);

  useEffect(() => {
    console.log(codeRes);

    if (codeRes?.status) {
      message[codeRes?.status](codeRes?.message || codeRes?.status);

      if (codeRes?.status === "success") {
        form.resetFields(["code", "role"]);
        setCodeSent(true);
      }
    }
    if (codeRes?.users)
      setRoles(
        codeRes.users?.map((user) => ({
          label: user.role?.toUpperCase(),
          value: user.role,
        }))
      );
    resetGetCode();
  }, [codeRes]);

  useEffect(() => {
    if (codeError?.message)
      message[codeError?.status || "error"](codeError?.message);
    resetGetCode();
  }, [codeError]);

  useEffect(() => {
    if (registerRes?.message)
      message[registerRes?.status](registerRes?.message);

    if (registerRes?.status === "success" && registerRes?.token) {
      localStorage.setItem("accessToken", registerRes?.token);
      navigate("/payment");
    }

    resetRegister();
  }, [registerRes]);

  useEffect(() => {
    if (registerError?.message)
      message[registerError?.status || "error"](registerError?.message);
    resetRegister();
  }, [registerError]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      values.role = "staff";
      console.log(values);
      await register(values);
      setCodeResend(true);
      // setResendTimer(resendInterval);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetCode = async () => {
    try {
      const values = await form.validateFields(["phone"]);
      values.role = "staff";
      localStorage.setItem("phone", values.phone);
      await getCode(values);
      setCodeResend(true);
      localStorage.setItem("role", values.role);

      setResendTimer(resendInterval);
      setIsCodeInputVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendCode = () => {
    setCodeResend(true);
    setResendTimer(resendInterval);
    handleGetCode();
  };

  const handleGoogleLogin = () => {
    window.location.href = "http://localhost:5000/auth/google";
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="login-title">Create an account</h2>
        <Form form={form} layout="vertical">
          <Form.Item
            name="fullName"
            rules={[
              { required: true, message: "Please enter your full name!" },
            ]}
          >
            <Input placeholder="Full Name" />
          </Form.Item>
          <Form.Item
            name="phone"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <PhoneNumberInput form={form} />
          </Form.Item>
          {codeSent && (
            <>
              <Form.Item
                name="code"
                rules={[{ required: true, message: "Please enter the code!" }]}
              >
                <Input.OTP />
              </Form.Item>
              {codeResend ? (
                <p className="resend-timer">
                  Resend OTP in {resendTimer} seconds
                </p>
              ) : (
                <Button type="link" onClick={handleResendCode}>
                  Resend OTP
                </Button>
              )}
            </>
          )}
          <Button
            type="primary"
            className="login-button primary"
            onClick={codeSent ? handleSubmit : handleGetCode}
          >
            {codeSent ? "Register" : "Verify"}
          </Button>
        </Form>
        <p className="signup-prompt">
          Already have an account?{" "}
          <Link to="/login" className="signup-link">
            Login
          </Link>
        </p>
        <div className="or-divider">
          <span>Or</span>
        </div>
        <Button
          onClick={handleGoogleLogin}
          className="login-button google"
          icon={<FaGoogle />}
          size="large"
        >
          Continue with Google
        </Button>
        <div id="telegram-login" className="login-button telegram">
          <FaTelegramPlane className="login-icon" />
          Continue with Telegram
        </div>
      </div>
    </div>
  );
};

export default UserRegistration;
